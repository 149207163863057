import { API_URL } from '../../config'
import Service from '../Service'

const resource = `${API_URL}`

export default {
  create (data) {
    return Service.post(`${resource}/update-facility-standards`, data)
  },
  update (id, data) {
    return Service.put(`${resource}/update-facility-standards/${id}`, data)
  },
  getOne () {
    return Service.get(`${resource}/FacilityStandards`)
  },
  getAll () {
    return Service.get(`${resource}/update-facility-standards`)
  },
  deleteOne (id) {
    return Service.delete(`${resource}/update-facility-standards/${id}`)
  },
}
